import { AnyZodObject, z } from 'zod'

import { BlueprintPoint } from '../schemas/public/BlueprintPoint.js'
import { OrganizationId } from '../schemas/public/Organization.js'
import {
  Project,
  ProjectInitializer,
  projectInitializer,
  ProjectMutator,
} from '../schemas/public/Project.js'

export type ProjectWithOrgInitializer = ProjectInitializer & {
  organizationId: OrganizationId
}

export type ProjectWithOrgMutator = ProjectMutator & {
  organizationId: OrganizationId
}

export type ProjectExtras = {
  activeSiteCount?: number
  cableCount?: number
  lastLogged?: string
  loggerCount?: number
  retiredSiteCount?: number
  siteCount?: number
  geo?: Array<{
    id: number
    name: string
    status: string
    visible: boolean
    latitude: number
    longitude: number
  }>
  changelog?: any
}

export type PointWithMetadata = {
  x: BlueprintPoint['x']
  y: BlueprintPoint['y']
  decode?: BlueprintPoint['decode']
  sensor?: BlueprintPoint['sensor']
  serial?: BlueprintPoint['serial']
  siteId?: BlueprintPoint['siteId']
  projectId?: BlueprintPoint['projectId']
}

export type ProjectWithOrganization = Project & {
  organizationId: OrganizationId
  organizationName: string
  blueprintPoints?: Array<PointWithMetadata>
  extras?: ProjectExtras
}

export const orgFields = z.object({
  organizationId: z.number().int().positive(),
})

export const projectWithOrgInitializer = orgFields.merge(
  projectInitializer as unknown as AnyZodObject,
) as unknown as z.Schema<ProjectWithOrgInitializer>

export const projectWithOrgMutator = orgFields.merge(
  projectInitializer as unknown as AnyZodObject,
) as unknown as z.Schema<ProjectWithOrgMutator>
