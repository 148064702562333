import type { AxiosRequestConfig } from 'axios'
import merge from 'lodash.merge'

import {
  BlueprintPointInitializer,
  ProjectId,
  ProjectWithOrganization,
} from '@beaded/models'

import type { Obj, ID } from 'types/common'

import * as apiUtils from '.'

const url = '/project'

export const get = (
  id: ID,
  options: AxiosRequestConfig = {},
): Promise<ProjectWithOrganization> =>
  apiUtils.GET(`${url}/${id}`, merge({}, apiUtils.extrasFlags, options))

export const getAll = (
  options: AxiosRequestConfig = {},
): Promise<ProjectWithOrganization[]> =>
  apiUtils.GET(`${url}/`, merge({}, apiUtils.extrasFlags, options))

export const create = (data: Obj) => apiUtils.POST(`${url}/`, data)

export const patch = (id: ID, data: Obj) => apiUtils.PATCH(`${url}/${id}`, data)

export const update = (id: ID, data: Obj) => apiUtils.PUT(`${url}/${id}`, data)

export const remove = (id: ID) => apiUtils.DELETE(`${url}/${id}`)

export const setBlueprintPoints = (
  id: ProjectId,
  data: BlueprintPointInitializer[],
) => apiUtils.PUT(`${url}/${id}/blueprintPoints`, { data })

export const getMenuProjection = (options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/`, merge({ params: { projection: 'menu' } }, options))

export const publicPage = {
  get: (id: ID, options: AxiosRequestConfig = {}) =>
    apiUtils.GET(
      `/public${url}/${id}`,
      merge({}, apiUtils.extrasFlags, options),
    ),
  getAll: (options: AxiosRequestConfig = {}) =>
    apiUtils.GET(`/public${url}/`, merge({}, apiUtils.extrasFlags, options)),
}

export const admin = {
  get: (id: ID, options: AxiosRequestConfig = {}) =>
    get(id, merge({}, apiUtils.globalFlags, options)),
  getAll: (options: AxiosRequestConfig = {}) =>
    getAll(merge({}, apiUtils.globalFlags, options)),
  getMenuProjection: (options: AxiosRequestConfig = {}) =>
    getMenuProjection(merge({}, apiUtils.globalFlags, options)),
}
